.dialogTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
}

.progress {
  color: green;
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-left: auto;
}

.html {
  width: auto;
  height: 280px;
  scroll-behavior: smooth;
}

.img {
  justify-content: center;
  align-self: center;
  max-width: 300px;
}


.loading {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}


.deficiencyTitleLable {
  font-size: 14px;
}

.horizontalScroll {
  display: flex;
  justify-content: left;
  overflow: hidden;
  overflow-x: scroll;
}

.scrollableY {
  max-height: 400px;
  overflow-y: scroll;
}

.header { 
  display: flex;
  align-self: auto;
}

.relatedImages {
  margin-top: 20px;
}
.description {
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
}

#solution {
  margin-top: 20px;
  font-family: 'Roboto';

}

#solution .title {
  font-size: 12px;
}

#solution .price {
  font-size: 10px;

}
.solution {
  margin-top: 20px;
}

.solution .headline {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: bolder;
}

.solution .message {
  font-family: 'Roboto';
  font-size: 12px;
 
}

.solution .products {
  display: flex;
  justify-content: center;
}
.solution .product {
  display: flex;

}

.product .img {
  max-width: 100px;
  max-height: 100px;
}

.product .metadata {
  display: flex;
  flex-direction: row;
}