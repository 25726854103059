.App {
  background-color: rgb(255, 255, 255);
  background-size: cover;
  background-repeat: repeat;
  height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
