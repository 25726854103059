.container {
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.content {
  margin: 10px;
  font-family: 'Roboto';
  font-size: 12px;
  display: flex;;
}

.img {
  max-width: 80px;
  max-height: 100px;
  margin-left: 20px;
}
.text {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.price {
  margin-top: 20px;

  margin-bottom: auto;
}