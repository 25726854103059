.container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.title {
    font-size: 24pt;
    font-weight: 600;
    color: green;
    margin-left: 20px;
    margin-right: 20px;
}

.subtitle {
    font-size: 18pt;
    color: green;
    font-weight: 500;
    margin-left: 20px;
    margin-right: 20px;
}

.button {
    font-size: 18px;
    font-weight: bold;
    color: green;

    margin-left: 20px;
}

.tryBtn {
    color: white;
    background-color: green;
    min-height: 44px;
    
}

.card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: 'background.paper';
    border: 2px solid #000;
}

.results {
    display: inline;
    align-items: center;
    margin: auto;
}

.doctor {
    padding: 40px;
}